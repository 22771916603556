html {
  background-color: #000;
}
.app {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .before-after-slider {
    min-width: 1600px;
  }
  .before-after-slider__delimiter {
    width: 2px;
  }
  .content {
    position: absolute;
    top: 100px;
    left: 100px;
    right: 100px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &__action {
      &--button {
        margin-top: 10px;
        width: 200px;
      }
      &--text {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #F7F7F7;
      }
      &--acent-text {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #00C74D;
      }
      &--bold-text {
        font-size: 48px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #F7F7F7;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 100px;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0) 100%);
    .payment {
      display: flex;
      gap: 30px;
      &__item {
        width: 100%;
        min-width: 50px;
      }
    }
    @media screen and (max-width: 1650px) {
      background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0) 100%);
      height: auto;
      .payment {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        &__item {
          width: 100%;
          min-width: 50px;
          max-width: 65px;
        }
      }
    }
  }
  .logo {
    width: 200px;
  }
  .main-button {
    padding: 12px 39px;
    background: #00C74D;
    border: 0;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    &:hover {
      background: #00E258;
    }
  }
  @media screen and (max-width: 1000px) {
    .content {
      top: 10px;
      left: 25px;
      right: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__action {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        order: 2;
        &--button {
          margin-top: 10px;
          width: 200px;
        }
        &--text {
          font-size: 14px;
        }
        &--acent-text {
          font-size: 14px;
        }
        &--bold-text {
          font-size: 24px;
        }
      }
      .content__logo {
        width: 100px;
        margin-bottom: 15px;
      }
    }
  }
}

::-webkit-scrollbar-thumb {
  background-color: #484E55;
}

::-webkit-scrollbar-track {
  background-color: #1A1E23;
}

::-webkit-scrollbar {
  width: 5px;
}